<template>
  <el-dialog
    custom-class="ql-dialog resourceDialog"
    :title="isEdit ? '编辑第三方资源' : '新增第三方资源'"
    :visible.sync="visible"
    width="630px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="118px">
      <el-form-item label="第三方资源名称" prop="resourceName" size="small">
        <el-input v-model="formData.resourceName" placeholder="请输入第三方资源名称，不多于10个字符"></el-input>
      </el-form-item>
      <el-form-item label="服务器地址" prop="resourceAddress" size="small">
        <el-input v-model="formData.resourceAddress" placeholder="请输入第三方资源服务器地址"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleAdd" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Form, FormItem, Input, Select, Option } from 'element-ui';
import { saveThirdresourceserver } from '@/api/authority-manage';
export default {
  name: 'ResourceDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogData: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateResourceName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('第三方资源名称不能为空'));
        return;
      }
      callback();
    };
    const validateResourceAddress = (rule, value, callback) => {
      if (!value) {
        callback(new Error('服务器地址不能为空'));
        return;
      }
      callback();
    };
    return {
      rules: {
        resourceName: [
          {
            validator: validateResourceName,
            trigger: 'blur',
          },
        ],
        resourceAddress: [
          {
            validator: validateResourceAddress,
            trigger: 'blur',
          },
        ],
      },
      formData: {
        resourceName: '',
        resourceAddress: '',
        resourceId: null,
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData = this.dialogData;
      }
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  methods: {
    handleAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveThirdresourceserver({
            serverAddress: this.formData.resourceAddress,
            serverName: this.formData.resourceName,
            id: this.isEdit ? this.formData.resourceId : undefined,
          }).then(
            (res) => {
              if (res && res.result === 0) {
                this.$emit('success', this.formData);
              } else {
                this.$message.error(res.msg);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
        return false;
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.form.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
.resourceDialog {
}
</style>
