// methods
import { getThirdresourceserver, removeThirdresourceserver } from '@/api/authority-manage';
// components
import { Button, Table, TableColumn, Input } from 'element-ui';
import backStageHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import resourceDialog from './components/resourceDialog';

export default {
  name: 'resourceAccess',
  props: {},
  data() {
    return {
      loading: false,
      searchValue: '',
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      isEdit: false,
      editIndex: -1,
      visible: false,
      formData: {
        resourceName: '',
        resourceAddress: '',
      },
    };
  },
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    backStageHeader,
    qlPagination,
    resourceDialog,
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getThirdresourceserver();
    },
    getThirdresourceserver(reset = false, searchVal) {
      let { pageIndex, pageSize } = this.listData;

      if (reset) pageIndex = this.listData.pageIndex = 1;

      this.loading = true;

      getThirdresourceserver({
        pageIndex,
        pageSize,
        serverName: searchVal,
      })
        .then(
          (res) => {
            let { recordCount, data } = res.result;
            this.listData.total = recordCount;
            if (data && data.length > 0) {
              this.listData.list = data;
            }
          },
          (err) => console.log(err)
        )
        .finally(() => {
          this.loading = false;
        });
    },

    handleAddNew() {
      this.formData = {
        resourceName: '',
        resourceAddress: '',
        resourceId: null,
      };
      this.isEdit = false;
      this.visible = true;
    },

    handleAdd() {
      this.saveThirdresourceserver();
    },

    handleSuccess(item) {
      if (this.isEdit && this.editIndex >= 0) {
        this.listData.list[this.editIndex].serverAddress = item.resourceAddress;
        this.listData.list[this.editIndex].serverName = item.resourceName;
      } else {
        this.getThirdresourceserver(true);
      }
      this.visible = false;
    },

    handleEdit(val, index) {
      const { id, serverName, serverAddress } = val;
      this.isEdit = true;
      this.editIndex = index;
      this.formData = {
        resourceName: serverName,
        resourceAddress: serverAddress,
        resourceId: id,
      };
      this.visible = true;
    },

    handleDel(val) {
      this.$msgbox
        .confirm('你确定删除该第三方资源吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          removeThirdresourceserver({
            id: val.id,
          }).then((res) => {
            if (res && res.status === 0) {
              let idx = this.listData.pageIndex;
              if (idx > 1 && this.listData.list && this.listData.list.length === 1) {
                idx--;
              }
              this.handleCurrentChange(idx);
            }
          });
        });
    },

    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getThirdresourceserver(true);
    },
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getThirdresourceserver();
    },

    handleChange(val) {
      this.getThirdresourceserver(true, val);
    },
  },
};
